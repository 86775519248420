import { motion } from "framer-motion";
import Image from "next/image";
import React, { useEffect } from "react";
import { IoClose } from "react-icons/io5";

import { validEmail } from "helpers/inputValidation";
import notification from "helpers/notification";
import { Karma } from "next/font/google";
import { useSubscribeNewsLetterMutation } from "services/api/baseApiSlice";

const font = Karma({
  weight: ["400", "600", "700"],
  subsets: ["latin"],
});

export default function NewsletterModal({
  closeModal,
}: {
  closeModal: () => void;
}) {
  const [email, setEmail] = React.useState(" ");
  const [subscribeNewsLetter, { isLoading, isSuccess }] =
    useSubscribeNewsLetterMutation();
  const handleSubmit = () => {
    subscribeNewsLetter({
      body: {
        email,
      },
    })
      .unwrap()
      .then(() => {
        notification({
          message: "You're on the list!",
          status: "success",
        } as any);
      })
      .catch((err) => {
        notification({
          message: err?.message ?? "An error occured. Please try again.",
          status: "error" as any,
        } as any);
      });
  };
  useEffect(() => {
    const handleEscape = (event: any) => {
      if (event.keyCode === 27) {
        closeModal();
      }
    };
    document.addEventListener("keydown", handleEscape);
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="fixed inset-0 z-[130] flex h-full w-full items-center justify-center bg-black/30 backdrop-blur-sm"
      id="backdrop"
      onClick={(e) => (e.target === e.currentTarget ? closeModal() : null)}
    >
      <motion.div
        initial={{ scale: 0.8, opacity: 0.7 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.5, opacity: 0, transition: { duration: 0.5 } }}
        transition={{ duration: 0.3, ease: [0.25, 0.1, 0.25, 1.0] }}
        className="relative z-50 flex h-[80vh] max-h-[600px] w-[90vw] max-w-[1080px] rounded-lg bg-white md:h-[90vh] md:w-[80vw] "
      >
        <div
          onClick={() => {
            closeModal();
          }}
          className="absolute -top-12 right-0 z-50 flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full bg-gray-500 md:-right-12 md:-top-6"
        >
          <IoClose className="" color="#fff" size={25} />
        </div>
        <div className={`b1024:flex relative hidden w-[80%]`}>
          <Image
            src={
              "https://res.cloudinary.com/knackapp/image/upload/v1688978993/static/newsletter/65dbafb82e74f18884bab14bd1fc9281.png"
            }
            fill
            alt="newsletter picture"
            className="object-cover"
            quality={100}
          />
        </div>
        <div className="ml-[40px] flex flex-col gap-4 md:gap-8">
          <h2
            className={`text-newsletter karma mt-[20%] text-[40px] font-bold leading-10 ${font.className}`}
          >
            Let&apos;s keep in touch.
          </h2>
          <div className="flex w-[90%] flex-col gap-0">
            <p
              className={`text-[24px] font-semibold text-[#018906] ${font.className}`}
            >
              Get the Future Talent Newsletter.
            </p>
            <p className="newsletter-text text-[18px] leading-8 text-[#585858]">
              Our bi-weekly newsletter is full with business insights, tips,
              trends and news shaping the future of talent.
            </p>
          </div>

          <form className="flex flex-col gap-2">
            <input
              type="email"
              placeholder="Type your email"
              className="text-newsletter newsletter-border mb-2 mt-10 h-[45px] w-[80%]  rounded-lg text-[18px]"
              required
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            <button
              className="h-[45px] w-[80%] rounded-lg bg-[#018906] text-[18px] font-medium text-white disabled:border-[#828282] disabled:bg-[#828282] disabled:text-gray-300"
              disabled={!validEmail(email) || isLoading}
              onClick={handleSubmit}
            >
              {isLoading
                ? "Loading..."
                : isSuccess
                ? "You're on the list!"
                : "Get the newsletter"}
            </button>
          </form>
        </div>
      </motion.div>
    </div>
  );
}

export function SubscribeSection() {
  const [email, setEmail] = React.useState(" ");
  const [subscribeNewsLetter, { isLoading, isSuccess }] =
    useSubscribeNewsLetterMutation();
  const handleSubmit = () => {
    subscribeNewsLetter({
      body: {
        email,
      },
    })
      .unwrap()
      .then(() => {
        notification({
          message: "You're on the list!",
          status: "success",
        } as any);
      })
      .catch((err) => {
        notification({
          message: err?.message ?? "An error occured. Please try again.",
          status: "error" as any,
        } as any);
      });
  };

  return (
    <div className="mx-auto flex w-full justify-between bg-[#F8F7F7] px-6 py-20 md:px-8">
      <div className="mx-auto flex w-full max-w-6xl flex-col justify-between gap-6 md:flex-row md:gap-0">
        <div className="flex flex-col gap-4 md:w-[60%] md:gap-0">
          <h2
            className={`font-bold text-[#FF546B] md:text-[28px] ${font.className} text-[20px]`}
          >
            Subscribe to Our Newsletter.
          </h2>
          <p className="newsletter-text text-[14px] font-medium text-[#585858] md:text-[18px] md:leading-8">
            Be the first to hear the latest business insights,{" "}
            <br className="hidden md:block" /> talent trends, and news shaping
            the world of talent.
          </p>
        </div>
        <form className="flex flex-col justify-start gap-2 md:w-[400px] md:items-end">
          <input
            type="email"
            placeholder="Type your email"
            className="newsletter-border-landing mb-2 h-[45px] w-full rounded-lg text-[18px]  text-[#FF546B] placeholder:text-[#FF546B] md:w-[80%]"
            required
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <button
            className="h-[45px] w-full rounded-lg bg-[#FF546B] text-[18px] font-medium text-white disabled:transform-none md:w-[80%]"
            disabled={!validEmail(email) || isLoading}
            onClick={handleSubmit}
          >
            {isLoading
              ? "Loading..."
              : isSuccess
              ? "You're on the list!"
              : "Get the newsletter"}
          </button>
        </form>
      </div>
    </div>
  );
}
